import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Header from '../../components/header/header';
import HeaderBottom from '../../components/headerBottom/headerBottom';
import NewsAndUpdates from '../../pageSections/home/newsAndUpdates/newsAndUpdates';
import Community from '../../pageSections/home/community/community';
import Mission from '../../pageSections/home/mission/mission';
import History from '../../pageSections/home/history/history';
import Safety from '../../pageSections/home/safety/safety';
import { SECTIONS } from '../../constants';
import { Helmet } from 'react-helmet';
import logo from '../../images/og-logo-twitter.png';
import HomeModal from '../../pageSections/home/homeModal/homeModal'

function HomePageTemplate({ handleHelmetTitle }) {
  const HomePageTemplateData = useStaticQuery(graphql`
    query HomePageQuery {
      allContentfulBurnabyRefinery(
        filter: {
          type: { type: { eq: "homePage" } }
          node_locale: { eq: "en-US" }
        }
      ) {
        edges {
          node {
            metadata
            id
            node_locale
            name
            slug
            title
            type {
              type
            }
            sections {
              ... on ContentfulContent {
                id
                title
                subText
                header
                type
                contentDetails {
                  raw
                }
              }
              ... on ContentfulContentList {
                id
                title
                type
                list {
                  contentDetails {
                    raw
                  }
                  title
                  type
                  subText
                  header
                }
              }
              ... on ContentfulCalenderEventList {
                id
                title
                type
                header
                text {
                  raw
                }
                calenderEvent {
                  date
                  textWithPath {
                    text
                    path
                  }
                  text {
                    raw
                  }
                }
                multimedia {
                  title
                  buttons {
                    raw
                  }
                }
              }
              ... on ContentfulImageContainer {
                id
                title
                type
                image {
                  file {
                    fileName
                    url
                  }
                }
                headerWithText {
                  headerText
                  text {
                    text
                  }
                }
                textWithPaths {
                  ... on ContentfulTextWithPath {
                    id
                    path
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const metaData =
    HomePageTemplateData?.allContentfulBurnabyRefinery?.edges[0]?.node;

  const homepageSections =
    HomePageTemplateData?.allContentfulBurnabyRefinery?.edges[0]?.node
      ?.sections;

  const pageTitle =
    HomePageTemplateData?.allContentfulBurnabyRefinery?.edges[0]?.node?.title;

  useEffect(() => {
    handleHelmetTitle(pageTitle);
  }, [pageTitle]);

  const homeModal = homepageSections?.filter((section) => {
    return section.type === SECTIONS.HOMEPAGE.HOMEMODAL;
  })[0];

  const homeHistory = homepageSections?.filter((section) => {
    return section.type === SECTIONS.HOMEPAGE.HOMEHISTORY;
  })[0];

  const homeSafetyContents = homepageSections?.filter((section) => {
    return section.type === SECTIONS.HOMEPAGE.HOMESAFETYCONTENTS;
  })[0];

  const homeSafetyMission = homepageSections?.filter((section) => {
    return section.type === SECTIONS.HOMEPAGE.HOMESAFETYMISSION;
  })[0];

  const richTextWithNewsUpdate = homepageSections?.filter((section) => {
    return section.type === SECTIONS.HOMEPAGE.RICHTEXTWITHNEWSUPDATE;
  })[0];

  const homeCommunityContents = homepageSections?.filter((section) => {
    return section.type === SECTIONS.HOMEPAGE.HOMECOMMUNITYCONTENTS;
  })[0];

  const homeImageContainer = homepageSections?.filter((section) => {
    return section.type === SECTIONS.HOMEPAGE.HOMEIMAGECONTAINER;
  })[0];
  
  const twitterImage = 'https://images.ctfassets.net/tatgxebmkmwo/X8hlhUAXNwpdlLCDJC801/ad35b8dc60ee27d393a4eb6047b91703/og-logo-twitter.png'

  return (
    <div>
      <Helmet>
        <title>
          {metaData?.title + ' - Burnaby Refinery' ?? 'Home - Burnaby Refinery'}
        </title>

        <meta http-equiv="X-UA-Compatible" content="IE=edge"></meta>
        {metaData.metadata && (
          <meta property="og:description" content={metaData.metadata}></meta>
        )}
        {metaData.title && (
          <meta property="og:title" content={metaData?.title}></meta>
        )}
        <meta property="og:type" content="website"></meta>
        <meta property="og:image" content={logo}></meta>
        <meta property="og:url" content={process.env.GATSBY_DOMAIN_URL}></meta>
        
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta name="twitter:site" content="https://burnabyrefinery.ca"></meta>
        {metaData.title && (
          <meta name="twitter:title" content={metaData.title}></meta>
        )}
        {metaData.metadata && (
          <meta name="twitter:description" content={metaData.metadata}></meta>
        )}
        <meta name="twitter:image" content={twitterImage}></meta>
        
      </Helmet>
      <Header data={homeImageContainer} />
      <HeaderBottom />
      { homeModal && (
        <HomeModal data={homeModal} />
      )}
      <History data={homeHistory} />
      <Safety data={homeSafetyContents} />
      <Mission data={homeSafetyMission} />
      <Community data={homeCommunityContents} />
      <NewsAndUpdates data={richTextWithNewsUpdate} />
    </div>
  );
}

export default HomePageTemplate;
